import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "navContent" ]

  showNavigation() {
    this.navContentTarget.setAttribute('style', 'display: block;');
  }

  hideNavigation() {
    this.navContentTarget.setAttribute('style', 'display: none;');
  }
}
