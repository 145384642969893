import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "container" ]

  initialize() {
    this.player = videojs(this.containerTarget, {
      controls: true,
      autoplay: false,
      preload: 'none',
      poster: this.data.get('posterUrl')
    })
  }
}
